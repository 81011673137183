<template>
  <card shadow style="padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; height: 120px;">
    <div class="flex">
      <div :class="isMobile ? 'flex' : 'block mb-2'">
        <img :src="id2 == 'cost_calculator_card' ? img : liveTrackingImg" class="mb-2 h-24 my-auto md:mr-2" />
      </div>
      <div class="ml-2">
        <h6 class="font-bold md:text-2xl text-sm" :class="isMobile ? 'pt-2' : ''">{{ id2 == 'cost_calculator_card' ? 'Shipping Rates' : 'Live Tracking'}}</h6>
        <p class=" md:text-sm text-xs pb-3 md:pb-0">{{ id2 == 'cost_calculator_card' ? 'Cek ongkir ke seluruh indonesia' : 'Live tracking ke seluruh indonesia'}}</p>
        <router-link :to="id2 == 'cost_calculator_card' ? '/costcalculator' : '/livetracking'">
          <b-button type="is-warning-gradient" style="padding-right: 30px; padding-left: 30px; height: 32px;">CEK</b-button>
        </router-link>
      </div>
    </div>
  </card>
</template>

<script>
import img from '@/assets/ipost/calc.png'
import Card from './Card'
export default {
  components: {
    Card
  },
  props: {
    id2: String
  },
  data () {
    return {
      img
    }
  },
  computed: {
     liveTrackingImg () {
      return this.company.MascotImages.LiveTracking
    }
  }
}
</script>

<style>

</style>
